<style scoped lang="less">
  .menu-salary {
    display: flex;
    flex-direction: column;
  }
  .month {
    margin-top: 15px;
    font-size: 17px;
    color: #FFF;
    line-height: 1;
  }
  .money {
    font-size: 20px;
    margin-top: 6px;
    font-weight: 800;
    color: #FFF;
  }
  .unit {
    font-size: 12px;
  }
  .chart {
    flex: 1;
    height: 0;
  }
</style>

<template>
  <div class="menu-salary">
    <div class="month">{{month}}</div>
    <div class="money">
      <span class="unit">¥</span>
      <span data-precision="2" v-num-to="data.yf || ''"></span>
    </div>
    <div ref="chart" class="chart"></div>
  </div>
</template>

<script>
import { wageRequest } from '@/api'
import {
  getLastMonth
} from '@/syslib/tools'
const echarts = require('echarts')

function getOption () {
  return {
    grid: {
      show: false,
      containLabel: false,
      left: 0,
      right: 0,
      top: 10,
      bottom: 10,
      // backgroundColor: '#FF788F'
    },
    xAxis: {
      type: 'category',
      data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
      axisLabel: { show: false },
      axisLine: { show: false },
      axisTick: { show: false }
    },
    yAxis: {
      type: 'value',
      splitLine: { show: false },
      axisLabel: { show: false },
      axisLine: { show: false },
      axisTick: { show: false }
    },
    series: [
      {
        data: [200, 230, 224, 200, 250, 300, 310],
        type: 'line',
        itemStyle: {
          color: '#FFF'
        },
        symbol: 'none',
        smooth: true,
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255,255,255,0.8)'
            },
            {
              offset: 1,
              color: 'rgba(255,255,255,0)'
            }
          ])
        }
      }
    ]
  }
}

export default {
  data () {
    return {
      data: {}
    }
  },
  computed: {
    month () {
      return getLastMonth()
    }
  },
  methods: {
    async loadData () {
      const data = await wageRequest.get({
        month: this.month
      })
      if (data.length) {
        this.data = data.pop()
      }
    }
  },
  mounted () {
    this.loadData()
    const echart = echarts.init(this.$refs.chart)
    echart.setOption(getOption(), true)
  },
}
</script>
